import React from 'react';

// Types
import { TAuthor } from '../../../utils/types';

// Atoms

// Contentful
import ContentfulImage from '../../../contentful/ContentfulImage';

// Organisms
import { Flex } from 'fatcat-ui-library/components/Atoms/Flex';
import { Text } from 'fatcat-ui-library/components/Atoms/Text';
import { useTheme } from 'styled-components';

const Author: React.FC<TAuthor & { published?: string, readingTime?: string }> = (props) => {
	const theme = useTheme();
	const {
		// city,
		image,
		name,
		about: { about },
	} = props;
	return (
		<>
			<Flex
				gap="48px"
				alignItems="center"
				paddingTop="s80"
				tablet={[{ display: 'flex' }]}
				display="none"
			>
				<Flex direction="column" w="fit-content">
					<Flex
						alignItems="center"
						fontSize="s24"
						fontWeight="semiBold"
						paddingBottom="s16"
						borderBottom={`1px solid ${theme.color.lines}`}
						marginBottom="s16"
						gap="16px"
					>
						<Flex w="60px">
							<ContentfulImage {...image} borderRadius="8px" />
						</Flex>
						<Text
							fontWeight="normal"
						>
							Written by
							<br />
							<Text textColor="green">
								{name}
							</Text>
						</Text>
					</Flex>
					<Flex
						gap="12px"
						alignItems="center"
						fontSize="s16"
					>
						{about && <Text>{about}</Text>}
					</Flex>
				</Flex>
			</Flex>
			<Flex
				gap="24px"
				alignItems="flex-start"
				paddingTop="s80"
				tablet={[{ display: 'none' }]}
			>
				<Flex paddingTop="s8" w="140px" largeTablet={[{ w: '180px' }]}>
					<ContentfulImage {...image} borderRadius="8px" />
				</Flex>
				<Flex direction="column" w="fit-content">
					<Flex
						alignItems="center"
						fontSize="s24"
						fontWeight="semiBold"
						paddingBottom="s16"
						borderBottom={`1px solid ${theme.color.lines}`}
						marginBottom="s16"
					>
						<Text
							fontWeight="normal"
						>
							Written by&nbsp;
							<Text textColor="green">{name}</Text>
						</Text>
					</Flex>
					<Flex
						gap="12px"
						alignItems="center"
						fontSize="s16"
					>
						{about && <Text>{about}</Text>}
					</Flex>
				</Flex>
			</Flex>
		</>
	);
};

Author.defaultProps = {
	published: undefined,
	readingTime: undefined,
};

export default Author;
