import React from 'react';
import { graphql, Link as GatsbyLink, navigate } from 'gatsby';
import { css } from 'styled-components';

// Types
import { TBlogPost } from '../utils/types';

// Atoms
import { Flex } from 'fatcat-ui-library/components/Atoms/Flex';
import { Heading } from 'fatcat-ui-library/components/Atoms/Heading/index';
import { Icon } from 'fatcat-ui-library/components/Atoms/Icon/Icon';
import { Link } from 'fatcat-ui-library/components/Atoms/Link';

// Organisms
import HeadMeta from '../components/Molecules/HeadMeta/HeadMeta';
import Section from 'fatcat-ui-library/components/Molecules/Section';

// Sections
import Hero from '../components/Sections/BlogPost/Hero';
import Content from '../components/Sections/BlogPost/Content';
import SocialShare from '../components/Molecules/Social/SocialShare';
import BlogCard from '../components/Molecules/Cards/BlogCard';
import Author from 'src/components/Organisms/Author/Author';
import { LuChevronLeft } from 'react-icons/lu';

export type TSingleBlogPost = {
	data: {
		blog: {
			relatedPosts: TBlogPost[]
		} & TBlogPost
		allContentfulBlogPost: {
			nodes: TBlogPost[];
		}
	}
}

const BlogPost: React.FC<TSingleBlogPost> = (props) => {
	const {
		data: {
			blog,
			allContentfulBlogPost: {
				nodes: blogs,
			},
		},
	} = props;

	return (
		<main>
			<article>
				<Section
					paddingY="s48"
					scale
				>
					<Link
						as={GatsbyLink}
						to="/blog/"
						onClick={() => {
							if (window.prevLocation) {
								navigate(-1);
							}
						}}
						fontWeight="medium"
						fontSize="s16"
						display="flex"
						alignSelf="center"
						mobile={[{ fontSize: 's14' }]}
						styled={css` align-items: center; `}
					>
						<Icon
							as={LuChevronLeft}
							w="16px"
							h="auto"
							margin={['r10', 'b2']}
						/>
						Back to Blog
					</Link>
				</Section>
				<Hero {...blog} />
				<Section
					paddingBottom="s24"
					scale
				>
					<Flex
						gap="130px"
						position="relative"
						largeTablet={[{ gap: '40px' }]}
						tablet={['column']}
					>
						<Flex
							maxW="140px"
							direction="column"
							gap="20px"
							position="sticky"
							top="140px"
							h="fit-content"
							tablet={['unsetPosition']}
						>
							<SocialShare />
						</Flex>
						<Flex maxW="658px">
							<Content {...blog} />
						</Flex>
					</Flex>
					{blog.author && <Author {...blog.author} />}
				</Section>
				{!!blogs.length && (
					<Section>
						<Heading
							as="h2"
							textAlign="center"
						>
							Related posts:
						</Heading>
						<Flex
							wrap
							gap="20px"
							padding={['t48', 'b80']}
							largeTablet={['justifyContentCenter']}
							tablet={[{ gap: '20px' }]}
						>
							{blogs.map(blogPost => (
								<BlogCard
									key={blogPost.title}
									excerpt={blogPost.excerpt}
									slug={blogPost.slug}
									previewImage={blogPost.previewImage}
									title={blogPost.title}
									fields={blogPost.fields}
									publishedAt={blogPost.publishedAt}
								/>
							))}
						</Flex>
					</Section>
				)}
			</article>
		</main>
	);
};

export default BlogPost;

export const Head: React.FC<TSingleBlogPost> = (props) => {
	const { data: { blog } } = props;
	return <HeadMeta meta={{ ...(blog.meta || {}), isCanonical: true }} />;
};

export const pageQuery = graphql`
    query BlogPostQuery($slug: String!) {
        blog: contentfulBlogPost(slug: {eq: $slug}) {
            author {
				...AuthorFields
			}
			meta {
				...MetaFields
			}
			title
            heroImage {
                ...ContentfulImage
            }
            post {
                raw
				references {
					...LinkFields
					...ContentfulImage
					...ProductCard
				}
            }
            slug
            fields {
                readingTime
            }
            publishedAt(formatString: "MMM DD, YYYY")
			createdAt(formatString: "MMM DD, YYYY")
        }
        allContentfulBlogTags {
            nodes {
                identifier
            }
        }
		allContentfulBlogPost(limit: 3, filter: {slug: {nin: [$slug, "placeholder-blog"]}}, sort: {order: DESC, fields: publishedAt}) {
		 	nodes {
		 		author {
		 			...AuthorFields
		 		}
		 		slug
		 		excerpt
		 		title
		 		previewImage {
		 			...AssetFields
		 			gatsbyImageData(placeholder: BLURRED)
		 		}
		 		publishedAt(formatString: "MMM DD, YYYY")
		 		fields {
		 			readingTime
		 		}
		 	}
         }
    }
`;
