import React from 'react';

// Types
import { TBlogPost } from '../../../utils/types';

// Atoms
import { Flex } from 'fatcat-ui-library/components/Atoms/Flex';

// Contentful
import RichText from '../../../contentful/RichText/RichText';

const Content: React.FC<TBlogPost> = ({ post }) => {
	return <Flex direction="column">{RichText(post)}</Flex>;
};

export default Content;
