import React from 'react';
import { useTheme } from 'styled-components';

// Types
import { TBlogPost } from '../../../utils/types';

// Atoms
import { Heading } from 'fatcat-ui-library/components/Atoms/Heading';
import { Flex } from 'fatcat-ui-library/components/Atoms/Flex';
import { Text } from 'fatcat-ui-library/components/Atoms/Text';
import ContentfulImage from '../../../contentful/ContentfulImage';

// Organisms
import Section from 'fatcat-ui-library/components/Molecules/Section';

const parseTitle = (title: string) => {
	// checks last character in string
	const regex = /[?!.]$/g;
	const haveChar = title.match(regex);
	return (haveChar ? (
		<>
			{title.substring(0, title.length - 1)}
			<Text textColor="green">{title.slice(-1)}</Text>
		</>
	) : title);
};

const Hero: React.FC<TBlogPost> = (props) => {
	const theme = useTheme();
	const {
		createdAt,
		publishedAt,
		title,
		heroImage,
		fields,
	} = props;

	return (
		<Section
			paddingBottom="s56"
			scale
		>
			<Flex
				alignItems="start"
				gap="130px"
				largeTablet={[{ gap: '52px' }]}
				tablet={['column', { gap: '42px' }]}
			>
				<Flex direction="column" gap="24px">
					<Heading as="h1">{parseTitle(title)}</Heading>
					<Flex
						gap="16px"
						alignItems="center"
						textColor="secondary"
						fontWeight="medium"
					>
						{fields?.readingTime && (
							<Text>{fields?.readingTime}</Text>
						)}
						<Text textColor="primary" fontWeight="bold"> · </Text>
						<Text>{publishedAt || createdAt}</Text>
					</Flex>
				</Flex>
				<Flex
					borderRadius="4px"
					overflow="hidden"
					maxW="40%"
					tablet={[{ maxW: '100%' }]}
				>
					<ContentfulImage {...heroImage} />
				</Flex>
			</Flex>
		</Section>
	);
};

export default Hero;
